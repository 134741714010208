( function ( $ ) {

	'use strict';

	const $body = $( 'body' );

	const pluginName = 'liquidToggle';
	let defaults = {
		type: 'click', // 'click', 'hover', 'hoverFade'
		cloneTriggerInTarget: false,
		closeOnOutsideClick: true, // boolean || { "ifNotIn": "$selector" }
		// changeClassnames: {'html': 'overflow-hidden'}
		toggleDelay: 300
	};

	class Plugin {

		constructor( element, options ) {

			this.element = element;
			this.$element = $( element );

			this.options = { ...defaults, ...options };

			this._defaults = defaults;
			this._name = pluginName;

			this.$targetElement = $( this.$element.attr( 'data-target' ) || this.$element.attr( 'data-bs-target' ) );
			this.$parentElement = this.$element.parent();
			this.isInVerticalBar = this.$element.closest( '.lqd-stickybar-wrap' ).length;
			this.isSearchModule = this.$parentElement.hasClass( 'ld-module-search' );
			this.isCartModule = this.$parentElement.hasClass( 'ld-module-cart' );
			this.$clonedTrigger = null;
			this.isOpened = false;
			this.isInHeader = $liquidMainHeader.has( this.element ).length;
			this.windowWidth = fastdom.measure( liquidWindowWidth )();
			this.targetRect = {};
			this.isBS5 = typeof bootstrap !== 'undefined';

			if (
				this.$element.hasClass( 'lqd-custom-menu-dropdown-btn' ) &&
				this.$element.parents( '.header-module' ).length
			) {
				this.options.type = 'hoverFade';
			}

			this.init();

		}

		async init() {

			const $targetToPosition = this.$targetElement.not( '.navbar-collapse' );

			if ( !this.isInVerticalBar && $targetToPosition.length && !liquidIsMobile() ) {
				$targetToPosition[ 0 ].classList.add( 'positioning' );
				await this.measure( $targetToPosition[ 0 ] );
				await this.positioning( $targetToPosition[ 0 ] );
			}

			this.addBodyClassnames();
			this.eventHandlers();
			this.cloneTriggerInTarget();
			this.cloneTargetInBody();

		}

		measure( targetElement ) {

			return fastdomPromised.measure( () => {

				return new Promise( resolve => {
					new IntersectionObserver( ( [ entry ], observer ) => {
						observer.disconnect();
						resolve( entry.boundingClientRect );
					} ).observe( targetElement );
				} );

			} ).then( rect => {

				this.targetRect = rect;

			} )

		}

		positioning( targetElement ) {

			return fastdomPromised.mutate( () => {

				if ( this.targetRect.width + this.targetRect.left >= this.windowWidth ) {
					targetElement.classList.remove( 'left' );
					targetElement.classList.add( 'right' );
				}
				if ( this.targetRect.left < 0 ) {
					targetElement.classList.remove( 'right' );
					targetElement.classList.add( 'left' );
				}

				targetElement.classList.remove( 'positioning' );

			} );

		}

		addBodyClassnames() {

			if ( this.$parentElement[ 0 ].hasAttribute( 'data-module-style' ) ) {

				$body.addClass( this.$parentElement.attr( 'data-module-style' ) );

			}

		}

		eventHandlers() {

			const { type } = this.options;

			if ( type === 'hover' ) {
				this.$element.on( 'mouseenter', () => {
					this.$targetElement.collapse( 'show' );
				} )
				this.$element.add( this.$targetElement ).on( 'mouseleave', () => {
					this.$targetElement.collapse( 'hide' );
				} )
			} else if ( type === 'hoverFade' && !liquidIsMobile() ) {

				fastdom.mutate( () => {

					let timeout = false;

					this.$targetElement.addClass( 'lqd-dropdown-fade-onhover' );
					// this.$targetElement.collapse('show');

					this.$element.add( this.$targetElement ).on( 'mouseenter', () => {
						this.$targetElement.addClass( 'is-active' );
						this.$targetElement.trigger( 'shown.bs.collapse' )
						timeout && clearTimeout( timeout );
					} );
					this.$element.add( this.$targetElement ).on( 'mouseleave', () => {
						timeout = setTimeout( () => {
							this.$targetElement.removeClass( 'is-active' );
							this.$targetElement.trigger( 'hidden.bs.collapse' )
							timeout && clearTimeout( timeout );
						}, this.options.toggleDelay );
					} );

				} );

			}

			if ( !this.isBS5 ) {
				this.$targetElement.on( 'show.bs.collapse', this.onShow.bind( this ) );
				this.$targetElement.on( 'shown.bs.collapse', this.onShown.bind( this ) );
				this.$targetElement.on( 'hide.bs.collapse', this.onHide.bind( this ) );
				this.$targetElement.on( 'hidden.bs.collapse', this.onHidden.bind( this ) );
			} else {
				this.$targetElement.each( ( i, el ) => {
					el.addEventListener( 'show.bs.collapse', this.onShow.bind( this ) );
					el.addEventListener( 'shown.bs.collapse', this.onShown.bind( this ) );
					el.addEventListener( 'hide.bs.collapse', this.onHide.bind( this ) );
					el.addEventListener( 'hidden.bs.collapse', this.onHidden.bind( this ) );
				} )
			}

			$( document ).on( 'click', event => {
				this.closeAll.call( this, event )
			} );

			$( document ).on( 'keyup', event => {

				if ( event.key === 'Escape' ) {
					this.closeAll.call( this, event );
				}

			} );

			if ( this.isInHeader ) {

				document.addEventListener( 'lqd-header-sticky-visibility-change', e => {
					if ( this.isOpened && e.detail.state === 'hide' ) {
						this.$targetElement.collapse( 'hide' )
					}
				} )

			}

		}

		onShow( e ) {

			const targetAttr = this.$element.attr( 'data-target' ) || this.$element.attr( 'data-bs-target' );

			$( 'html' ).addClass( 'module-expanding' );

			if ( this.isSearchModule ) {
				$( 'html' ).addClass( 'lqd-module-search-expanded' );
			} else if ( this.isCartModule ) {
				$( 'html' ).addClass( 'lqd-module-cart-expanded' );
			}

			this.$targetElement.add( this.element ).add( this.$clonedTrigger ).addClass( 'is-active' );

			if ( targetAttr.replace( '#', '' ) === $( e.target ).attr( 'id' ) ) {
				this.toggleClassnames();
				this.focusOnSearch();
			}

			this.isOpened = true;

			const timeout = setTimeout( () => {
				this.$clonedTrigger.removeClass( 'collapsed' );
				this.$targetElement.removeClass( 'collapse' );
				timeout && clearTimeout( timeout );
			}, 10 );

		}

		onShown() {

			$( 'html' ).removeClass( 'module-expanding' );

			if ( window.liquidLazyload ) {
				window.liquidLazyload.update();
			}

		}

		onHide( e ) {

			const targetAttr = this.$element.attr( 'data-target' ) || this.$element.attr( 'data-bs-target' );

			$( 'html' ).addClass( 'module-collapsing' );

			this.$targetElement.add( this.element ).add( this.$clonedTrigger ).removeClass( 'is-active' );

			if ( targetAttr.replace( '#', '' ) === $( e.target ).attr( 'id' ) ) {

				this.toggleClassnames();

			}

			this.isOpened = false;

			const timeout = setTimeout( () => {
				this.$clonedTrigger.addClass( 'collapsed' );
				this.$targetElement.addClass( 'collapse' );
				timeout && clearTimeout( timeout );
			}, 10 );

		}

		onHidden() {

			$( 'html' ).removeClass( 'module-collapsing lqd-module-search-expanded lqd-module-cart-expanded' );

		}

		toggleClassnames() {

			// { "element": "classname, classname" }
			$.each( this.options.changeClassnames, ( element, classname ) => {
				$( element ).toggleClass( classname, !this.isOpened );
			} );

			if (
				!this.options.changeClassnames &&
				this.$targetElement.hasClass( 'navbar-fullscreen' )
			) {
				$liquidHtml.toggleClass( 'overflow-hidden', !this.isOpened );
			}

		}

		focusOnSearch() {

			const self = this;

			if ( self.$targetElement.find( 'input[type=search]' ).length ) {

				setTimeout( function () {

					self.$targetElement.find( 'input[type=search]' ).focus().select();

				}, 150 );

			}

		}

		shouldIGetClosed( $target ) {

			const { closeOnOutsideClick } = this.options;

			if ( typeof closeOnOutsideClick === 'boolean' ) {
				return closeOnOutsideClick;
			} else {
				const { ifNotIn } = closeOnOutsideClick;
				const $ifNotInEl = $( ifNotIn );
				return !$ifNotInEl.has( $target ).length ? true : false;
			}

		}

		closeAll( event ) {

			const { closeOnOutsideClick } = this.options;
			const shouldIGetClosed = this.shouldIGetClosed( this.$targetElement );

			if (
				event.keyCode ||
				(
					!this.$targetElement.is( event.target ) &&
					!this.$targetElement.has( event.target ).length
				)
			) {

				if (
					( closeOnOutsideClick && shouldIGetClosed ) ||
					( typeof closeOnOutsideClick === 'boolean' && closeOnOutsideClick )
				) {


					if ( this.isBS5 ) {
						new bootstrap.Collapse( this.$targetElement[ 0 ], {
							toggle: false,
							show: false,
							hide: true,
						} )
					}

					this.$targetElement.collapse( 'hide' );

				}

			}

		}

		cloneTriggerInTarget() {

			// only for mobile nav.
			// and when mobile nav style is set to modern
			if (
				( this.$targetElement.attr( 'id' ) === 'lqd-mobile-sec-nav' && $body.attr( 'data-mobile-nav-style' ) === 'modern' ) ||
				this.options.cloneTriggerInTarget ||
				this.$targetElement.hasClass( 'navbar-fullscreen' )
			) {

				this.$clonedTrigger = this.$element.clone( true ).prependTo( this.$targetElement );

			}

		}

		cloneTargetInBody() {

			// only for mobile nav.
			// and when mobile nav style is set to modern
			if (
				this.$targetElement.attr( 'id' ) === 'lqd-mobile-sec-nav' &&
				$body.attr( 'data-mobile-nav-style' ) === 'modern'
			) {

				this.$targetElement.children( '.main-nav, .header-module' ).wrapAll( '<div class="navbar-collapse-inner"></div>' );

			}

		}

	}

	$.fn[ pluginName ] = function ( options ) {

		return this.each( function () {

			const pluginOptions = { ...$( this ).data( 'toggle-options' ), ...options };

			if ( !$.data( this, "plugin_" + pluginName ) ) {
				$.data( this, "plugin_" + pluginName, new Plugin( this, pluginOptions ) );
			}

		} );

	};

}( jQuery ) );

jQuery( document ).ready( function ( $ ) {
	$( '[data-ld-toggle]' ).liquidToggle();
} );